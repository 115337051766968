<template>
  <section>
    <v-container fluid grid-list-xl>
      <v-layout row wrap>
        <v-flex xs12 lg4 body-1 grey--text text--darken-1>Peso: <strong>{{peso}} kg</strong></v-flex>
        <v-flex xs12 lg4 body-1 grey--text text--darken-1>Numero colli: <strong> {{colli}}</strong></v-flex>
      </v-layout>
      <v-divider></v-divider>
      <v-layout row wrap>
        <v-flex xs12 lg12>
          <v-alert
            outline
            color="info"
            value="true"
            v-if="!merci.length && !bancaliDdt.length">
            Nessuna merce/bancale trovato
          </v-alert>
          <!-- lista bancali -->
          <v-list class="mt-2" two-line v-if="bancaliDdt.length">
            <v-list-group
              class="listIcon"
              sub-group
              no-action
              v-for="(bancale, index) in bancaliDdt"
              v-model="bancale.active"
              :key="'tile-'+bancale.num_bancale">
              <v-list-tile slot="activator">
                <v-list-tile-content>
                  <v-list-tile-title>{{bancale.num_bancale + " - " + bancale.ragione_sociale}}</v-list-tile-title>
                  <v-list-tile-sub-title>Componenti: {{bancale.componenti.length}}</v-list-tile-sub-title>
                </v-list-tile-content>
                <v-list-tile-action>
                  <v-list-tile-action-text>Qtà {{bancale.qta_prelevata}}</v-list-tile-action-text>
                  <v-icon @click.stop @click="eliminaBancali(bancale)" color="error">delete</v-icon>
                </v-list-tile-action>
              </v-list-tile>
              <v-list-tile v-for="comp in bancale.componenti" :key="comp.componente_id">
                <v-list-tile-content>
                  <v-list-tile-title class="rowWrapper">
                    <v-layout row wrap class="rowWrapper-row">
                      <v-flex sm12 lg2>
                        <v-text-field
                          label="Opera"
                          v-model="comp.opera"
                          disabled />
                      </v-flex>
                      <v-flex sm12 lg3>
                        <v-text-field
                          label="Descrizione"
                          v-model="comp.descrizione"
                          disabled />
                      </v-flex>
                      <v-flex sm12 lg2>
                        <v-text-field
                          label="Codice"
                          v-model="comp.codice"
                          disabled />
                      </v-flex>
                      <v-flex sm12 lg2>
                        <v-text-field
                          label="Componente"
                          v-model="comp.componente"
                          disabled />
                      </v-flex>
                      <v-flex sm12 lg3>
                        <v-text-field
                          label="Qtà"
                          v-model="comp.qta"
                          disabled />
                      </v-flex>
                    </v-layout>
                  </v-list-tile-title>
                </v-list-tile-content>
              </v-list-tile>
              <v-divider :key="'divider-'+bancale._id" v-if="index < bancaliDdt.length - 1" inset></v-divider>
            </v-list-group>
          </v-list>
          <v-divider :key="'divisore'" v-if="bancaliDdt.length > 0 && merci.length > 0"></v-divider>
          <!-- lista merci libere -->
          <v-list class="mt-2" two-line v-if="merci.length">
            <template v-for="(merce, index) in merci">
              <v-list-tile
                @click="openMerceLibera(merce)"
                avatar
                :key="'tile-'+merce.merce_id">
                <v-list-tile-content>
                  <v-list-tile-title><span class="text-uppercase">{{ merce.codice }}</span> - {{ merce.descrizione }}</v-list-tile-title>
                  <v-list-tile-sub-title>{{merce.unita_misura}}: {{merce.qta}}</v-list-tile-sub-title>
                </v-list-tile-content>
                <v-list-tile-action>
                  <v-list-tile-action-text>Qtà {{merce.qta}}</v-list-tile-action-text>
                  <v-icon @click.stop @click="eliminaMerce(merce)" color="error">delete</v-icon>
                </v-list-tile-action>
              </v-list-tile>
              <v-divider :key="'div-'+merce.merce_id" v-if="index < merci.length - 1" inset></v-divider>
            </template>
          </v-list>
          <v-speed-dial
            class="fix-speed-dial"
            v-model="fab"
            bottom
            right
            fixed
            direction="top"
            transition="slide-y-reverse-transition">
            <v-btn
              color="pink darken-1"
              dark fab right bottom
              slot="activator"
              v-model="fab">
              <v-icon>add</v-icon>
              <v-icon>close</v-icon>
            </v-btn>
            <v-btn
              fab
              dark
              small
              color="green"
              @click="openMerceLibera()">
              <v-icon>edit</v-icon>
            </v-btn>
            <v-btn
              fab
              dark
              small
              color="indigo"
              v-if="ddt.destinazione_id"
              @click="openBancali(1)">
              <v-icon>build</v-icon>
            </v-btn>
          </v-speed-dial>
        </v-flex>
      </v-layout>
      <v-layout row wrap>
        <v-flex>
          <v-btn @click="stampa()">
            Stampa
          </v-btn>
        </v-flex>
      </v-layout>
    </v-container>
    <!-- merci libere -->
    <v-dialog v-model="showMerce" persistent max-width="500" transition="dialog-bottom-transition">
      <v-card>
        <v-toolbar class="grey lighten-3" card>
          <v-toolbar-title>
            Merce libera
          </v-toolbar-title>
          <v-spacer />
          <v-btn icon @click.stop="showMerce = false"><v-icon>close</v-icon></v-btn>
        </v-toolbar>
        <v-card-text class="pa-0">
          <v-card>
            <v-form ref="formMerceLibera" v-model="validFormMerce">
              <v-container fluid grid-list-xl>
                <v-layout row wrap>
                  <v-flex>
                    <v-text-field
                      label="Codice"
                      v-model="merce.codice"
                      :rules="[$rules.required, $rules.maxlength(20)]" />
                  </v-flex>
                </v-layout>
                <v-layout row wrap>
                  <v-flex>
                    <v-textarea
                      auto-grow
                      label="Descrizione lavorazione"
                      v-model="merce.descrizione"
                      :rules="[$rules.required, $rules.maxlength(1000)]" />
                  </v-flex>
                </v-layout>
                <v-layout row wrap>
                  <v-flex>
                    <v-text-field
                      label="Unità di misura"
                      v-model="merce.unita_misura"
                      :rules="[$rules.required, $rules.maxlength(20)]" />
                  </v-flex>
                </v-layout>
                <v-layout row wrap>
                  <v-flex>
                    <v-text-field
                      label="Quantità"
                      v-model="merce.qta"
                      :rules="[$rules.required, $rules.numeric, $rules.maxlength(7)]" />
                  </v-flex>
                </v-layout>
                <v-layout row wrap>
                  <v-flex>
                    <v-text-field
                      label="Peso unitario (in kg)"
                      v-model="merce.peso"
                      :rules="[$rules.maxlength(9)]" />
                  </v-flex>
                </v-layout>
                <v-layout row wrap>
                  <v-flex>
                    <v-text-field
                      label="Numero colli"
                      v-model="merce.colli"
                      :rules="[$rules.numeric, $rules.maxlength(4)]" />
                  </v-flex>
                </v-layout>
                <v-layout row wrap>
                  <v-flex>
                    <v-btn
                      class="ml-0"
                      color="primary"
                      :disabled="!validFormMerce"
                      @click.native="saveMerce()">
                      Salva
                    </v-btn>
                  </v-flex>
                </v-layout>
              </v-container>
            </v-form>
          </v-card>
        </v-card-text>
      </v-card>
    </v-dialog>
    <!-- bancali -->
    <v-dialog v-model="showBancali" transition="dialog-bottom-transition" :overlay="false" max-width="900" scrollable>
      <v-card>
        <v-toolbar class="grey lighten-3" card>
          <v-toolbar-title>
            Bancali disponibili
          </v-toolbar-title>
          <v-spacer />
          <v-btn color="primary" @click="aggiungiBancali()" :disabled="!selectedBancali.length">Aggiungi</v-btn>
          <v-btn icon @click.stop="showBancali = false"><v-icon>close</v-icon></v-btn>
        </v-toolbar>
        <v-card-text>
          <v-flex>
            <v-layout>
              <v-flex xs4>
                <v-text-field
                  class="ma-2"
                  label="Descrizione"
                  v-model="ricerca.descrizione" />
              </v-flex>
              <v-flex xs4>
                <v-text-field
                  class="ma-2"
                  label="Componente"
                  v-model="ricerca.componente" />
              </v-flex>
              <v-flex xs4>
                <v-text-field
                  class="ma-2"
                  label="Codice"
                  v-model="ricerca.codice" />
              </v-flex>
            </v-layout>
            <v-layout>
              <v-flex xs12 class="pa-2">
                <v-btn
                  class="ml-0"
                  color="primary"
                  @click.native="openBancali()">
                  Filtra
                </v-btn>
                <v-btn
                  @click="openBancali(1)">
                  Ripristina
                </v-btn>
              </v-flex>
            </v-layout>
          </v-flex>
          <v-form ref="formBancali">
            <v-list v-if="bancaliDisponibili.length">
              <v-list-group
                v-for="bancale in bancaliDisponibili"
                v-model="bancale.active"
                :key="'tile-'+bancale.num_bancale"
                no-action>
                <v-list-tile slot="activator" avatar>
                  <v-list-tile-avatar @click.stop>
                    <v-checkbox v-model="selectedBancali" :value="bancale._id" />
                  </v-list-tile-avatar>
                  <v-list-tile-content>
                    <v-list-tile-title>{{bancale.num_bancale}}</v-list-tile-title>
                  </v-list-tile-content>
                </v-list-tile>
                <v-list-tile v-for="comp in bancale.componenti" :key="comp.componente_id">
                  <v-list-tile-content>
                    <v-list-tile-title class="rowWrapper">
                      <v-layout row wrap class="rowWrapper-row">
                        <v-flex sm12 lg3>
                          <v-text-field
                          class="mr-3"
                          label="Componente"
                          v-model="comp.componente"
                          disabled />
                        </v-flex>
                        <v-flex sm12 lg3>
                          <v-text-field
                          class="mr-3"
                          label="Codice"
                          v-model="comp.codice"
                          disabled />
                        </v-flex>
                        <v-flex sm12 lg4>
                          <v-text-field
                          class="mr-3"
                          label="Descrizione"
                          v-model="comp.descrizione"
                          disabled />
                        </v-flex>
                        <v-flex sm12 lg2>
                          <v-text-field
                          class="mr-3"
                          label="Quantità"
                          v-model="comp.qta"
                          disabled />
                        </v-flex>
                      </v-layout>
                    </v-list-tile-title>
                  </v-list-tile-content>
                </v-list-tile>
              </v-list-group>
            </v-list>
          </v-form>
        </v-card-text>
      </v-card>
    </v-dialog>
    <modal-confirm ref="confirm" />
  </section>
</template>

<script>
import { $EventBus } from '@/eventBus'
import { ddtMixin } from '@/components/magazzino/ddt/ddtMixin'
import modalConfirm from '@/components/common/ModalConfirm'
import _debounce from 'lodash/debounce'

export default {
  mixins: [ddtMixin],
  props: {
    ddt: Object
  },
  data: () => ({
    merci: [],
    merce: {},
    ricerca: {},
    showMerce: false,
    showBancali: false,
    validFormMerce: true,
    validFormBancali: true,
    bancaliDisponibili: [],
    bancaliDdt: [],
    selectedBancali: [],
    searchOpere: null,
    searchProdotti: null,
    opereLoading: false,
    prodottiLoading: false,
    opere: [],
    prodotti: [],
    peso: 0,
    colli: 0,
    fab: false,
    hover: false
  }),
  components: {
    'modal-confirm': modalConfirm
  },
  methods: {
    isEmpty (obj) {
      return (obj.keys && obj.keys.length)
    },
    getMerci () {
      this.$plsqlMethod('ddtm', 'get_merci_ddt', { id: this.ddt._id })
        .then(response => { this.merci = response.data })
        .catch(err => {
          $EventBus.$emit('message', { type: 'error', text: err.data.errMessage })
        })
    },
    getBancali () {
      this.$plsqlMethod('ddtm', 'get_bancali_ddt', { id: this.ddt._id })
        .then(response => { this.bancaliDdt = response.data })
        .catch(err => {
          $EventBus.$emit('message', { type: 'error', text: err.data.errMessage })
        })
      this.$plsqlMethod('ddtm', 'get_peso', { id: this.ddt._id })
        .then(response => {
          this.peso = response.data.peso
          this.colli = response.data.colli
        })
        .catch(err => {
          $EventBus.$emit('message', { type: 'error', text: err.data.errMessage })
        })
    },
    openMerceLibera (m) {
      if (m) {
        this.merce = m
      } else {
        this.merce = {}
        this.$refs.formMerceLibera.reset()
      }
      this.showMerce = true
    },
    openBancali (reset) {
      if (this.ddt.destinazione_id) {
        if (reset) this.ricerca = { }

        this.ricerca.id = this.ddt.cliente_id

        this.$plsqlMethod('ddtm', 'get_bancali_disponibili', this.ricerca)
          .then(response => {
            this.bancaliDisponibili = response.data
            this.selectedBancali = []
            this.showBancali = true
          })
          .catch(err => {
            $EventBus.$emit('message', { type: 'error', text: err.data.errMessage })
          })
      }
    },
    saveMerce () {
      let t = this
      this.merce.ddt_id = this.ddt._id
      if (this.$refs.formMerceLibera.validate()) {
        if (!this.merce.merce_id) {
          this.$plsqlMethod('ddtm', 'aggiungi_merci', this.merce)
            .then(response => {
              this.showMerce = false
              t.getMerci()
              t.getBancali()
            })
        } else {
          this.$plsqlMethod('ddtm', 'modifica_merci', this.merce)
            .then(response => {
              this.showMerce = false
              t.getMerci()
              t.getBancali()
            })
        }
      }
    },
    aggiungiBancali () {
      let t = this
      this.$plsqlMethod('ddtm', 'aggiungi_bancali', { id: this.ddt._id, bancali: this.selectedBancali })
        .then(response => {
          this.showBancali = false
          t.getBancali()
        })
        .catch(err => {
          $EventBus.$emit('message', { type: 'error', text: err.data.errMessage })
        })
    },
    eliminaBancali (b) {
      let ddtId = this.ddt._id
      let t = this
      this.$refs.confirm.show({
        modalTitle: 'Conferma eliminazione',
        modalText: 'Sei sicuro di voler eliminare i bancali <strong>' + b.num_bancale + ' - ' + b.ragione_sociale + '</strong>?',
        modalButtonOk: 'Elimina',
        modalFunctionOk: function (banc) {
          this.$plsqlMethod('ddtm', 'rimuovi_bancali', { id: ddtId, bancali: [banc] })
            .then(response => {
              t.$refs.confirm.hide()
              t.getBancali()
            })
            .catch(err => {
              $EventBus.$emit('message', { type: 'error', text: err.data.errMessage })
            })
        },
        modalObj: b
      })
    },
    eliminaMerce (m) {
      let t = this
      this.$refs.confirm.show({
        modalTitle: 'Conferma eliminazione',
        modalText: 'Sei sicuro di voler eliminare la merce <strong>' + m.codice + ' - ' + m.descrizione + '</strong>?',
        modalButtonOk: 'Elimina',
        modalFunctionOk: function (merc) {
          this.$plsqlMethod('ddtm', 'rimuovi_merci', { merce_id: merc.merce_id })
            .then(response => {
              t.$refs.confirm.hide()
              t.getMerci()
            })
            .catch(err => {
              $EventBus.$emit('message', { type: 'error', text: err.data.errMessage })
            })
        },
        modalObj: m
      })
    },
    cercaOpere (val) {
      if (!val) return

      this.opereLoading = true

      this.$plsqlMethod('opera', 'search', { text: val, cliente: this.ddt.cliente_id, attivo: 1 })
        .then(response => {
          this.opere = response.data
          this.opereLoading = false
        })
        .catch(err => {
          console.log(err)
          $EventBus.$emit('message', { type: 'error', text: 'Errore lettura Opere' })
          this.opereLoading = false
        })
    },
    cercaProdotti (val) {
      if (!val) return

      this.prodottiLoading = true

      this.$plsqlMethod('opera', 'searchp', { text: val, cliente: this.ddt.cliente_id, attivo: 1 })
        .then(response => {
          this.prodotti = response.data
          this.prodottiLoading = false
        })
        .catch(err => {
          console.log(err)
          $EventBus.$emit('message', { type: 'error', text: 'Errore lettura Prodotti' })
          this.prodottiLoading = false
        })
    },
    stampa () {
      let processDdtForMail = this.processDdtForMail
      this.$plsqlMethod('ddtm', 'get_ddt_for_mail', { ddts: [this.ddt._id] })
        .then((response) => {
          response.data.forEach(function (ddt) {
            ddt = processDdtForMail(ddt)
          })
          this.$http({
            method: 'post',
            url: '/mail/ddt/pdf',
            responseType: 'blob',
            data: { ddts: response.data }
          }).then((response) => {
            const blob = new Blob([response.data], { type: 'application/pdf' })
            this.$openFileNewTab(blob) // con adblocker picche ocio
          }).catch((err) => {
            console.log(err)
            $EventBus.$emit('message', { type: 'error', text: 'Impossibile creare il pdf del ddt' })
          })
        })
        .catch((err) => {
          console.log(err)
          $EventBus.$emit('message', { type: 'error', text: 'Impossibile creare il pdf del ddt' })
        })
    }
  },
  watch: {
    searchOpere (val) {
      this.debouncedcercaOpere(val)
    },
    searchProdotti (val) {
      this.debouncedcercaProdotti(val)
    }
  },
  created: function () {
    this.debouncedcercaOpere = _debounce(this.cercaOpere, 500)
    this.debouncedcercaProdotti = _debounce(this.cercaProdotti, 500)
  },
  mounted () {
    this.getMerci()
    this.getBancali()
  }
}
</script>

<style>
  .fix-speed-dial.v-speed-dial--direction-top .v-speed-dial__list{
    left: -1em!important;
    bottom: 150%!important;
  }
  .v-list__group {
    border-bottom: 1px solid rgba(0,0,0,0.12);
  }
  .rowWrapper {
    height: auto;
    line-height: inherit;
    padding: 0;
  }
  .rowWrapper-row {
    height: 90px;
    margin-top: 0!important;
  }
  .underline--text {
    text-decoration: underline;
  }

  .listIcon .v-list__group__header__prepend-icon {
    padding: 0 0 0 16px;
  }
</style>
